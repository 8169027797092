import React from 'react'
import { getTrackedReplyStats } from '../api/trackers'
import { getSummaryDashboard } from '../api/dashboards'
import RowDataCard from '@mc/common/components/Cards/RowDataCard'
import { AppContext, UPDATE_COMPETITIVE_DATA, UPDATE_TRACKING_DATA } from '../App/AppContext'
import { TIME_FRAME_OPTIONS } from '@mc/common/components/Toggles/TimeFrameToggle'
import { AuthenticationContext } from '../App/AuthenticationContext'

const TrackerCard = () => {
    const [authState,] = React.useContext(AuthenticationContext)
    const [appState, appDispatch] = React.useContext(AppContext)

    const getUpdateSummaryDashboard = () => {
        getSummaryDashboard(appState.timeWindow, authState).then(res => {
            appDispatch(
                {
                    type: UPDATE_TRACKING_DATA,
                    payload: {
                        total: res.data.total_emails[0] || 0,
                        totalperc: Math.ceil(res.data.total_emails[1]) || 0,
                        potential: res.data.total_potential_spying_attempts[0] || 0,
                        potentailperc: Math.ceil(res.data.total_potential_spying_attempts[1]) || 0,
                        known: res.data.total_known_spying_attempts[0] || 0,
                        knownperc: Math.ceil(res.data.total_known_spying_attempts[1]) || 0,
                    }
                }
            )
        })

    }

    React.useEffect(getUpdateSummaryDashboard, [appState.timeWindow])

    const getUpdateTrackedReplyStats = () => {
        getTrackedReplyStats(appState.timeWindow, authState).then(res => {
            appDispatch(
                {
                    type: UPDATE_COMPETITIVE_DATA,
                    payload: {
                        total_conversations: res.data.total_conversations[0] || 0,
                        totalperc: Math.ceil(res.data.total_conversations[1]) || 0
                    }
                }
            )
        })

    }
    React.useEffect(getUpdateTrackedReplyStats, [appState.timeWindow])

    const rows = [
        {
            text: `Total emails scanned in the ${TIME_FRAME_OPTIONS[appState.timeWindow].contextualizedDisplayText}`,
            statistic: appState.trackingData.total || 0,
            change: appState.trackingData.totalperc || 0,
        },
        {
            text: `Replies to tracked emails in the ${TIME_FRAME_OPTIONS[appState.timeWindow].contextualizedDisplayText}`,
            statistic: appState.competitiveTrackingData.total_conversations || 0,
            change: appState.competitiveTrackingData.totalperc || 0,
        },
        {
            text: `Potential tracking attempts secured in the ${TIME_FRAME_OPTIONS[appState.timeWindow].contextualizedDisplayText}`,
            statistic: appState.trackingData.potential || 0,
            change: appState.trackingData.potentailperc || 0,
        },
        {
            text: `Known tracking attempts secured in the ${TIME_FRAME_OPTIONS[appState.timeWindow].contextualizedDisplayText}`,
            statistic: appState.trackingData.known || 0,
            change: appState.trackingData.knownperc || 0,
        },

    ]

    return (
        <RowDataCard
            headerText='Trackers'
            subText='Blocks embedded email trackers'
            rows={rows}
            infoLink={{ linkText: 'View Trackers', to: '/trackers' }
            }
        />
    )
}

export default TrackerCard
