import React from 'react'
import PageHeader from '@mc/common/components/Page/PageHeader'
import EmailAlertsCard from './EmailAlertsCard'
import TrackerCard from './TrackerCard'
import { Grid, makeStyles } from '@material-ui/core'
import { AppContext, UPDATE_TIME_WINDOW } from '../App/AppContext';
import TimeFrameToggle from '@mc/common/components/Toggles/TimeFrameToggle';

const useStyles = makeStyles((theme) => ({
    summaryGrid: {
        flexGrow: 1,
    },
    card: {
        paddingTop: 20,
        paddingBottom: 20,
        borderRadius: 6,
    },

}));

const Summary = () => {
    const classes = useStyles();
    const [appState, appDispatch] = React.useContext(AppContext)

    const handleTimeWindow = (newTimeWindow) => {
        appDispatch({
            type: UPDATE_TIME_WINDOW,
            payload: newTimeWindow
        })
    }

    return (
        <>
            <PageHeader pageTitle="Overview" subText="CyberGraph Overview" />
            <TimeFrameToggle timeWindow={appState.timeWindow} onChange={handleTimeWindow} />
            <div className={classes.summaryGrid}>
                <Grid container spacing={3}>
                    <Grid item md={12} lg={6}>
                        <EmailAlertsCard />
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <TrackerCard />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Summary
