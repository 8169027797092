import React from 'react'
import { fetchCodebreakerDashboardData } from '../api/codebreaker'
import { processRawCodeBreakerSummaryData } from '../utils/codebreakerData'
import RowDataCard from '@mc/common/components/Cards/RowDataCard'
import { AppContext, UPDATE_EMAIL_ALERT_DATA } from '../App/AppContext'
import { TIME_FRAME_OPTIONS } from '@mc/common/components/Toggles/TimeFrameToggle'
import { AuthenticationContext } from '../App/AuthenticationContext'

const EmailAlertsCard = () => {
    const [authState,] = React.useContext(AuthenticationContext)
    const [appState, appDispatch] = React.useContext(AppContext)

    const getUpdateCodebreakerDashboardData = () => {
        fetchCodebreakerDashboardData(appState.timeWindow, authState).then(res =>
            appDispatch(
                {
                    type: UPDATE_EMAIL_ALERT_DATA,
                    payload: processRawCodeBreakerSummaryData(res.data, authState.customerInfo.computed_users)
                })

        )
    }

    React.useEffect(getUpdateCodebreakerDashboardData, [appState.timeWindow])

    const rows = [
        {
            text: `Average alert ratio ${TIME_FRAME_OPTIONS[appState.timeWindow].contextualizedDisplayText}`,
            statistic: `${(appState.emailAlertData.alert_ratio * 100 || 0).toFixed(2)}%`,
            change: appState.emailAlertData.alert_ratio_change || 0
        },
        {
            text: 'Average alerts per inbox per day',
            statistic: appState.emailAlertData.average_alerts_per_inbox_per_day || 0,
            change: appState.emailAlertData.average_alerts_per_inbox_per_day_change || 0
        },
        {
            text: 'Emails reported by users',
            statistic: appState.emailAlertData.sum_reports || 0,
        },
        {
            text: 'Emails marked as safe by users',
            statistic: appState.emailAlertData.sum_safe || 0,
        },

    ]

    return (
        <RowDataCard
            headerText='Dynamic Banners'
            subText='Displays intelligent external warning messages'
            rows={rows}
            infoLink={{ linkText: 'View Dynamic Banners', to: '/dynamic-banners' }
            }
        />
    )
}

export default EmailAlertsCard
