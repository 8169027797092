import React from 'react'
import Summary from '../Summary/Summary'
import ProtectedViewLayout from '../App/ProtectedViewLayout';

const SummaryPage = ({ location }) => {
    return (
        <ProtectedViewLayout location={location}>
            <Summary />
        </ProtectedViewLayout>
    );
}

export default SummaryPage